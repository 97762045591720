import { Form, Input, Modal, Radio } from 'antd';
import { observer } from 'mobx-react';
import type React from 'react';
import { useEffect, useState } from 'react';

interface IProps {
  visible?: boolean;
  onClose?: () => void;
  onSubmit?: (values: any) => void;
  [key: string]: any;
}
const FeedBack = observer(({ store }: { store: IProps }) => {
  const { visible, onClose, onSubmit, setRef, onChange, isHidden } = store;
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue({ intention: '1' });
  }, [form]);
  console.log(store);
  return (
    <Modal
      title="反馈意愿"
      open={visible}
      width="60%"
      centered={true}
      onOk={onSubmit}
      onCancel={onClose}
    >
      <Form
        ref={setRef}
        name="dynamic_form_item"
        layout="vertical"
        onFieldsChange={onChange}
      >
        <Form.Item
          name="intention"
          label="是否有意愿"
          layout="horizontal"
          initialValue={'1'}
          rules={[{ required: true, message: '请选择审核结果' }]}
        >
          <Radio.Group>
            <Radio value="1">是</Radio>
            <Radio value="2">否</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="reason"
          label="原因"
          dependencies={['intention']}
          hidden={isHidden}
          rules={[
            {
              required: !isHidden,
            },
            { message: '请输入原因' },
          ]}
        >
          <Input.TextArea
            placeholder="请输入原因"
            maxLength={200}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
});

export { FeedBack };
