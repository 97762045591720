import { type BaseData, request } from '@/utils';
import { type FormInstance, message } from 'antd';
import { action, observable } from 'mobx';
import { API } from '../../api';
import type Model from '../model';

export default class FeedBackModel {
  @observable public visible = false;
  @observable public ref: FormInstance;
  @observable public intentionId: number | string | null;

  @observable public isHidden = true;
  constructor(parentStore: Model) {
    this.parentStore = parentStore;
    // this.mainSubStructureModel?.gridModel?.onQuery();
  }
  public parentStore: Model;
  @action public setRef = (ref: FormInstance): void => {
    this.ref = ref;
  };
  // 打开审核弹窗
  @action
  public onOpenFeedBackModal = (intentionId) => {
    this.visible = true;
    this.intentionId = intentionId;
  };
  // 关闭审核弹窗
  @action
  public onClose = () => {
    this.visible = false;
    this.ref.setFieldsValue({
      intention: '1',
      reason: '',
    });
    this.isHidden = true;
    this.intentionId = null;
  };

  @action
  public onSubmit = async () => {
    await this.ref.validateFields();
    const values = await this.ref.getFieldsValue();
    console.log(values, '校验通过');
    await request<BaseData<any>>({
      url: API.feedback,
      method: 'POST',
      data: {
        intentionId: this.intentionId,
        ...values,
      },
    });
    message.success('操作成功');
    this.parentStore.mainStore.grid.gridModel.onQuery();
    this.onClose();
  };

  @action
  public onChange = (fields: any) => {
    const values = this.ref.getFieldsValue();
    this.isHidden = values.intention !== '2';
  };
}
