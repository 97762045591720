export const API = {
  // 未合作职位列表
  noCooperateList: '/pbb/platform/sys/unBind/position/list',
  // 上下线职位
  enabelPostion: '/pbb/platform/sys/position/onOrOffPositionById',
  // 批量上线职位
  batchEnablePosition: '/pbb/platform/sys/position/batchOnOrOffPosition',
  // 批量下线职位
  batchDisablePosition: '/pbb/platform/sys/position/batchOffPosition',
  // 审核职位
  approvalPostion: '/pbb/platform/sys/position/auditPosition',
  // 弹窗关联雇主
  confirmRelevancy: '/pbb/platform/sys/confirm/relation/employer',
  // 关联雇主
  relevancyEmployer: '/pbb/platform/sys/relation/employer',
  // 导入职位JD
  importJd: '/pbb/platform/anon/sys/excel/import',
  // 导入职位JD-获取导入模板
  getImportTemplate: '/pbb/platform/sys/position/getImportTemplate',
  // 虚拟雇主管理
  virtualEmployer: '/pbb/platform/sys/virtual/employer/list',
  // 反馈意愿
  feedback: '/pbb/platform/sys/feedback/intention',
};
